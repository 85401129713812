<template>
  <div>
    <div class="title">
      <div class="title_left">
        <img
          src="@/icons/svg/chart-icon.svg"
          alt=""
          class="title_icon"
        />可视化图表
      </div>
      <div class="title_right" @click="back">
        <img
          src="@/icons/svg/back.svg"
          alt=""
          class="title_icon_right"
        />返回上一页
      </div>
    </div>
    <div>
      <div class="senior_page main_background">
        <div style="margin-top: 20px; margin-left: 5%">
          <el-form
            :model="queryParam"
            ref="form"
            :rules="rules"
            label-width="auto"
            label-position="right"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="处罚时间"
                  prop="punTimeType"
                  style="margin-left: 1.15rem"
                >
                  <el-radio-group
                    v-model="queryParam.punTimeType"
                    style="padding-left: 0px; padding-right: 0px"
                  >
                    <el-radio :label="1">本月</el-radio>
                    <el-radio :label="2">本季度</el-radio>
                    <el-radio :label="3">本年</el-radio>
                    <el-radio :label="4">自定义</el-radio>
                  </el-radio-group>
                  <el-date-picker
                    unlink-panels
                    v-model="queryParam.punTimeRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    value-format="yyyy-MM-dd"
                    v-show="queryParam.punTimeType == 4"
                    :clearable="false"
                    style="
                      height: 0.16666666rem;
                      width: 1.25rem;
                      margin-left: 5px;
                    "
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item
                  label="监管发布时间"
                  prop="govPubTimeType"
                  style="margin-left: -35px"
                >
                  <el-radio-group
                    v-model="queryParam.govPubTimeType"
                    style="padding-left: 0px; padding-right: 0px"
                  >
                    <el-radio :label="1">本月</el-radio>
                    <el-radio :label="2">本季度</el-radio>
                    <el-radio :label="3">本年</el-radio>
                    <el-radio :label="4">自定义</el-radio>
                  </el-radio-group>
                  <el-date-picker
                    unlink-panels
                    v-model="queryParam.govPubTimeRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    value-format="yyyy-MM-dd"
                    v-show="queryParam.govPubTimeType == 4"
                    :clearable="false"
                    style="
                      height: 0.16666666rem;
                      width: 1.25rem;
                      margin-left: 5px;
                    "
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <img
                  src="@/assets/image/punish/redT.png"
                  style="
                    margin-left: 12%;
                    margin-top: 1.2%;
                    position: absolute;
                    height: 15px;
                  "
                />
                <el-form-item
                  label="机构类型"
                  prop="punObjectList"
                  style="margin-top: 8px; margin-left: 1.15rem"
                >
                  <el-select
                    v-model="queryParam.punObjectList"
                    multiple
                    collapse-tags
                    style="width: 2.71rem"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, index) in punObjectOptions"
                      :key="index"
                      :label="item.punObject"
                      :value="item.punObject"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-show="isShowPunJyType">
                <el-form-item
                  label="兼业代理类型"
                  style="margin-top: 8px; margin-left: -35px"
                >
                  <el-checkbox-group v-model="punJyType">
                    <el-checkbox
                      v-for="(item, index) in tagJyList"
                      :label="item.tagDetId"
                      :key="index"
                      :value="item.tagDetId"
                      >{{ item.detName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row style="margin-top: 10px">
              <el-col :span="12">
                <el-form-item
                  label="处罚主体"
                  style="margin-top: 8px; margin-left: 1.15rem"
                >
                  <el-input
                    type="input"
                    class="el-input-coler"
                    readonly
                    @click.native="morePunSub"
                    style="width: 2.71rem"
                    v-model="punSubZK"
                  >
                    <template #suffix>
                      <img
                        style="padding-right: 10px; padding-top: 0.0721rem"
                        v-if="!zhutiFlag"
                        src="@/assets/image/icon/jiahao.png"
                      />
                      <img
                        style="padding-right: 10px; padding-top: 0.0721rem"
                        v-if="zhutiFlag"
                        src="@/assets/image/icon/jianhao.png"
                      />
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="" class="backlabel" v-if="zhutiFlag">
              <div
                style="width: 90%; display: flex; margin-left: 0.785416666rem"
              >
                <el-checkbox
                  v-model="cityCheckAll"
                  style="margin-right: 0.1rem"
                  @change="cityCheckAllChange"
                  >全部</el-checkbox
                >
                <div style="display: inline-block; width: 80%">
                  <div
                    style="margin-left: 17px"
                    v-for="(city, index) in cities"
                    :key="index"
                  >
                    <div
                      v-for="(item, key) in city"
                      :label="key"
                      :key="key"
                      style="display: inline-block; width: 18%; margin-right: 0"
                    >
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <div>
                          <el-checkbox
                            v-model="cityStatus[key]"
                            @change="(e) => firstCitiesChange(e, key)"
                            >{{ key }}</el-checkbox
                          >
                        </div>
                        <div
                          style="padding-right: 10px"
                          @click="showMoreCitys(key)"
                        >
                          <img
                            src="@/assets/image/icon/xia.png"
                            v-show="whichCity != key"
                            style="cursor: pointer"
                          />
                          <img
                            src="@/assets/image/icon/shang.png"
                            v-show="whichCity == key"
                            style="cursor: pointer"
                          />
                        </div>
                        <img
                          src="@/assets/image/icon/shangsj.png"
                          v-show="whichCity == key"
                          style="
                            cursor: pointer;
                            position: absolute;
                            margin-left: 0.15rem;
                            margin-top: 0.18229167rem;
                          "
                        />
                      </div>
                    </div>
                    <div
                      style="
                        background: #f3f9ff;
                        border: 0px solid #cee2f7;
                        margin-left: -17px;
                        padding-left: 17px;
                      "
                      v-for="(item2, key2) in city"
                      :key="item2.id"
                    >
                      <div v-show="whichCity == key2" style="padding-left: 0px">
                        <div
                          v-for="item3 in item2"
                          :label="item3.name"
                          :key="item3.id"
                          style="display: inline-block; width: 18%"
                        >
                          <el-checkbox
                            @change="(e) => secondCitiesChange(e, item3, key2)"
                            v-model="item3.checked"
                            >{{ item3.name }}</el-checkbox
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="分析维度"
                  prop="dimensionList"
                  style="margin-top: 8px; margin-left: 1.15rem"
                >
                  <el-checkbox-group v-model="queryParam.dimensionList">
                    <el-checkbox
                      v-for="(item, index) in dimensionDictList"
                      :label="item.value"
                      :key="index"
                      >{{ item.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-col
              style="
                margin-top: 26px;
                margin-bottom: 30px;
                display: flex;
                justify-content: center;
              "
            >
              <el-button
                @click="search"
                style="
                  width: 0.46875rem;
                  height: 0.1666666rem;
                  background-color: #2696ffff;
                  color: #ffffff;
                  border: 1px solid #2696ff;
                  display: inline-block;
                  line-height: 0px;
                "
                >生成图表</el-button
              >
              <el-button
                @click="reset"
                style="
                  width: 0.46875rem;
                  height: 0.1666666rem;
                  border: 1px solid #2696ff;
                  font-weight: bolder;
                  color: #2696ff;
                  display: inline-block;
                  line-height: 0px;
                "
                >重置</el-button
              >
            </el-col>
          </el-form>
        </div>
      </div>
    </div>
    <div style="display: flex">
      <div class="chart">
        <div v-show="!isEmpty" style="width: 1200px; padding-bottom: 30px">
          <div v-if="showCharts.includes(1)">
            <div style="display: flex">
              <div class="chart_top">
                <div class="chart_title">
                  <img
                    src="@/assets/image/punish/file.png"
                    alt=""
                  />总体处罚情况
                </div>
              </div>
              <div class="chart_action">
                <img src="@/icons/svg/change-chart.svg" alt="" />
                <span
                  style="margin-left: 5px"
                  @click="ChartOrTableChange('isMainChange')"
                  class="cur"
                  >列表视图</span
                ><img
                  src="@/icons/svg/download.svg"
                  alt=""
                  style="margin-right: 5px; margin-left: 20px"
                  v-show="!isMainChange"
                /><span
                  @click="
                    exportDoubleImg([
                      { dom: 'punishQuantity', name: '总体处罚情况-罚单数量' },
                      { dom: 'punishAmount', name: '总体处罚情况-罚款金额' },
                    ])
                  "
                  class="cur"
                  v-show="!isMainChange"
                  >下载</span
                >
              </div>
            </div>
            <div style="display: flex" v-if="!isMainChange">
              <div>
                <div class="main_punish_quantity">罚单数量</div>
                <div
                  ref="punishQuantity"
                  style="
                    width: 560px;
                    height: 376px;
                    display: flex;
                    justify-content: center;
                    background: #f8f9fb;
                    margin-right: 8px;
                    margin-left: 36px;
                  "
                ></div>
              </div>
              <div>
                <div class="main_punish_amount">罚款金额</div>
                <div
                  ref="punishAmount"
                  style="
                    width: 560px;
                    height: 376px;
                    display: flex;
                    justify-content: center;
                    background: #f8f9fb;
                    margin-right: 36px;
                  "
                ></div>
              </div>
            </div>
            <div style="display: flex" v-if="isMainChange">
              <div
                style="
                  background: #f8f9fb;
                  padding: 11px 24px 24px 24px;
                  margin-left: 34px;
                "
              >
                <div class="table_title">
                  罚单数量
                  <div class="table_title_right">单位：张</div>
                </div>

                <div
                  style="
                    width: 500px;
                    display: flex;
                    justify-content: center;
                    background: #f8f9fb;
                    margin-right: 15px;
                  "
                >
                  <el-table
                    :data="quantityData"
                    border
                    style="width: 100%"
                    :header-cell-style="{ backgroundColor: '#EFF4F9' }"
                  >
                    <el-table-column
                      fixed
                      prop="name"
                      label="类别"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="value"
                      label="罚单数（张）"
                      align="center"
                    >
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div
                style="
                  background: #f8f9fb;
                  padding: 8px 24px 24px 24px;
                  margin-left: 8px;
                "
              >
                <div class="table_title">
                  罚款金额
                  <div class="table_title_right">单位：万元</div>
                </div>
                <div
                  style="
                    width: 500px;
                    display: flex;
                    justify-content: center;
                    background: #f8f9fb;
                    margin-right: 8px;
                  "
                >
                  <el-table
                    :data="amountData"
                    border
                    style="width: 100%"
                    :header-cell-style="{ backgroundColor: '#EFF4F9' }"
                  >
                    <el-table-column
                      fixed
                      prop="name"
                      label="类别"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="value"
                      label="罚款金额（万元）"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column prop="ratio" label="占比" align="center">
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
          <div v-show="showCharts.includes(2)">
            <div style="display: flex">
              <div class="chart_top">
                <div class="chart_title">
                  <img
                    src="@/assets/image/punish/area.png"
                    alt=""
                  />各地区处罚情况分布
                </div>
              </div>
              <div class="chart_action">
                <img src="@/icons/svg/change-chart.svg" alt="" />
                <span
                  style="margin-left: 5px"
                  @click="ChartOrTableChange('isSingleAreaChange')"
                  class="cur"
                  >列表视图</span
                ><img
                  src="@/icons/svg/download.svg"
                  alt=""
                  style="margin-right: 5px; margin-left: 20px"
                  v-show="!isSingleAreaChange"
                /><span
                  @click="exportImg('singleAreaChart', '各地区处罚情况分布')"
                  class="cur"
                  v-show="!isSingleAreaChange"
                  >下载</span
                >
              </div>
            </div>
            <div style="display: flex" v-if="!isSingleAreaChange">
              <div
                ref="singleAreaChart"
                style="
                  width: 1128px;
                  height: 376px;
                  display: flex;
                  justify-content: center;
                  background: #f8f9fb;
                  margin-right: 8px;
                  margin-left: 36px;
                "
              ></div>
            </div>
            <div
              style="
                overflow: auto;
                margin-left: 33px;
                padding-left: 33px;
                margin-right: 37px;
                background: #f8f9fb;
                padding: 24px;
              "
              v-if="isSingleAreaChange"
            >
              <el-table
                :data="dataList"
                border
                :header-cell-style="{ backgroundColor: '#EFF4F9' }"
              >
                <el-table-column
                  v-for="(item, key) in titleList"
                  :label="item.title"
                  :prop="item.key"
                  :width="item.key === '类别' ? '200' : ''"
                  :align="item.key !== '类别' ? 'center' : ''"
                ></el-table-column>
              </el-table>
            </div>
          </div>

          <div v-show="showCharts.includes(3)">
            <div style="display: flex">
              <div class="chart_top">
                <div class="chart_title">
                  <img
                    src="@/assets/image/punish/tiao.png"
                    alt=""
                  />各地区双罚情况
                </div>
              </div>
              <div class="chart_action">
                <img src="@/icons/svg/change-chart.svg" alt="" />
                <span
                  style="margin-left: 5px"
                  @click="ChartOrTableChange('isDoublePunishChange')"
                  class="cur"
                  >列表视图</span
                ><img
                  src="@/icons/svg/download.svg"
                  alt=""
                  style="margin-right: 5px; margin-left: 20px"
                  v-show="!isDoublePunishChange"
                /><span
                  @click="exportImg('doubleAreaChart', '各地区双罚情况')"
                  class="cur"
                  v-show="!isDoublePunishChange"
                  >下载</span
                >
              </div>
            </div>
            <div style="display: flex" v-if="!isDoublePunishChange">
              <div
                ref="doubleAreaChart"
                style="
                  width: 1128px;
                  height: 376px;
                  display: flex;
                  justify-content: center;
                  background: #f8f9fb;
                  margin-right: 8px;
                  margin-left: 36px;
                "
              ></div>
            </div>
            <div
              style="
                overflow: auto;
                margin-left: 33px;
                padding-left: 33px;
                margin-right: 37px;
                background: #f8f9fb;
                padding: 24px;
              "
              v-if="isDoublePunishChange"
            >
              <el-table
                :data="doubleDataList"
                border
                :header-cell-style="{ backgroundColor: '#EFF4F9' }"
              >
                <el-table-column
                  v-for="(item, key) in doubleTitleList"
                  :label="item.title"
                  :prop="item.key"
                  :width="item.key === '类别' ? '150' : ''"
                  :align="item.key !== '类别' ? 'center' : ''"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div v-show="showCharts.includes(5)">
            <div style="display: flex">
              <div class="chart_top">
                <div class="chart_title">
                  <img
                    src="@/assets/image/punish/double.png"
                    alt=""
                  />处罚人员分布
                </div>
              </div>
              <div class="chart_action">
                <img src="@/icons/svg/change-chart.svg" alt="" />
                <span
                  style="margin-left: 5px"
                  @click="ChartOrTableChange('isShowPeopleChart')"
                  class="cur"
                  >列表视图</span
                ><img
                  src="@/icons/svg/download.svg"
                  alt=""
                  style="margin-right: 5px; margin-left: 20px"
                  v-show="!isShowPeopleChart"
                />
                <span
                  @click="generateImage"
                  class="cur"
                  v-show="!isShowPeopleChart"
                  >下载</span
                >
              </div>
            </div>
            <div
              style="background: #f8f9fb; width: 95%; margin-left: 32px"
              v-if="!isShowPeopleChart"
              id="peopleChart"
            >
              <!-- <div
                ref="peopleChart"
                style="
                  width: 1128px;
                  height: 376px;
                  display: flex;
                  justify-content: center;
                  background: #f8f9fb;
                  margin-right: 8px;
                  margin-left: 36px;
                "
              ></div> -->
              <div
                style="
                  display: flex;
                  padding-top: 30px;
                  padding-left: 22px;
                  padding-bottom: 20px;
                  margin-left: 60px;
                "
              >
                <div>
                  <div
                    v-for="(item, index) in peopleData"
                    style="
                      display: flex;
                      font-size: 14px;
                      margin-bottom: 15px;
                      transform: rotate(180deg);
                    "
                    class="left_people"
                  >
                    <el-progress
                      style="width: 390px"
                      :show-text="true"
                      :text-inside="true"
                      :stroke-width="22"
                      color="#5B8FF9"
                      :percentage="sumPercentFun(item, index)"
                      :format="numFormat(index)"
                    ></el-progress>
                  </div>
                </div>
                <div style="margin-top: -3px">
                  <div
                    v-for="(item, index) in peopleData"
                    style="
                      width: 180px;
                      line-height: 28px;
                      background: rgb(0, 104, 255);
                      margin-bottom: 9px;
                      border-radius: 6px;
                      font-size: 16px;
                      color: white;
                      text-align: center;
                    "
                  >
                    {{ item.identityCategory }}
                  </div>
                </div>
                <div>
                  <div
                    v-for="(item, index) in peopleData"
                    style="display: flex; font-size: 14px; margin-bottom: 15px"
                  >
                    <el-progress
                      style="width: 390px"
                      :show-text="true"
                      :text-inside="true"
                      :stroke-width="22"
                      color="#67D4A9 "
                      :percentage="moneyPercentFun(item, index)"
                      :format="moneyFormat(index)"
                    ></el-progress>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                margin-left: 25%;
                width: 50%;
                background: #f8f9fb;
                padding: 24px;
              "
              v-if="isShowPeopleChart"
            >
              <el-table
                :data="peopleData"
                border
                style=""
                :header-cell-style="{ backgroundColor: '#EFF4F9' }"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  width="60"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="identityCategory"
                  label="职务"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="punMoney"
                  label="处罚金额（万元）"
                  width="154"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="punPerNum"
                  label="罚单数（张）"
                  width="154"
                  align="center"
                ></el-table-column>
              </el-table>
            </div>
          </div>

          <div v-show="showCharts.includes(6)">
            <div style="display: flex">
              <div class="chart_top">
                <div class="chart_title">
                  <img
                    src="@/assets/image/punish/four_square.png"
                    alt=""
                  />行政处罚类别
                </div>
              </div>
              <div class="chart_action">
                <img src="@/icons/svg/change-chart.svg" alt="" />
                <span
                  style="margin-left: 5px"
                  @click="ChartOrTableChange('isAdminDataChange')"
                  class="cur"
                  >列表视图</span
                ><img
                  src="@/icons/svg/download.svg"
                  alt=""
                  style="margin-right: 5px; margin-left: 20px"
                  v-show="!isAdminDataChange"
                /><span
                  @click="exportImg('adminiChart', '行政处罚类别')"
                  class="cur"
                  v-show="!isAdminDataChange"
                  >下载</span
                >
              </div>
            </div>
            <div style="display: flex" v-if="!isAdminDataChange">
              <div
                ref="adminiChart"
                style="
                  width: 1128px;
                  height: 376px;
                  display: flex;
                  justify-content: center;
                  background: #f8f9fb;
                  margin-right: 8px;
                  margin-left: 36px;
                "
              ></div>
            </div>
            <div
              style="
                margin-left: 37%;
                width: fit-content;
                background: #f8f9fb;
                padding: 24px;
              "
              v-if="isAdminDataChange"
            >
              <el-table
                :data="adminData"
                border
                style=""
                :header-cell-style="{ backgroundColor: '#EFF4F9' }"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  width="60"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="othorName"
                  label="处罚类别"
                  width="154"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="punNum"
                  label="次数"
                  width="154"
                  align="center"
                ></el-table-column>
              </el-table>
            </div>
          </div>

          <div v-show="showCharts.includes(7)">
            <div style="display: flex">
              <div class="chart_top">
                <div class="chart_title">
                  <img
                    src="@/assets/image/punish/pie.png"
                    alt=""
                  />金监局处罚主体层级
                </div>
              </div>
              <div class="chart_action">
                <img src="@/icons/svg/change-chart.svg" alt="" />
                <span
                  style="margin-left: 5px"
                  @click="ChartOrTableChange('isShowMainChange')"
                  class="cur"
                  >列表视图</span
                ><img
                  src="@/icons/svg/download.svg"
                  alt=""
                  style="margin-right: 5px; margin-left: 20px"
                  v-show="!isShowMainChange"
                /><span
                  @click="
                    exportDoubleImg([
                      {
                        dom: 'mainPunishQuantity',
                        name: '金监局处罚主体层级-罚单数量',
                      },
                      {
                        dom: 'mainPunishAmount',
                        name: '金监局处罚主体层级-罚款金额',
                      },
                    ])
                  "
                  class="cur"
                  v-show="!isShowMainChange"
                  >下载</span
                >
              </div>
            </div>
            <div style="display: flex" v-if="!isShowMainChange">
              <div>
                <div class="main_punish_quantity">罚单数量</div>
                <div
                  ref="mainPunishQuantity"
                  style="
                    width: 560px;
                    height: 376px;
                    display: flex;
                    justify-content: center;
                    background: #f8f9fb;
                    margin-right: 8px;
                    margin-left: 36px;
                  "
                ></div>
              </div>
              <div>
                <div class="main_punish_amount">罚款金额</div>
                <div
                  ref="mainPunishAmount"
                  style="
                    width: 560px;
                    height: 376px;
                    display: flex;
                    justify-content: center;
                    background: #f8f9fb;
                    margin-right: 36px;
                  "
                ></div>
              </div>
            </div>
            <div style="display: flex" v-if="isShowMainChange">
              <div
                style="
                  background: #f8f9fb;
                  padding: 11px 24px 24px 24px;
                  margin-left: 34px;
                "
              >
                <div class="table_title">
                  罚单数量
                  <div class="table_title_right">单位：张</div>
                </div>
                <div
                  style="
                    width: 500px;
                    display: flex;
                    justify-content: center;
                    background: #f8f9fb;
                    margin-right: 8px;
                  "
                >
                  <el-table
                    :data="mainPunishTableData"
                    show-summary
                    border
                    style="width: 100%"
                    :header-cell-style="{ backgroundColor: '#EFF4F9' }"
                    :summary-method="getNumSummaries"
                  >
                    <el-table-column
                      fixed
                      prop="type"
                      label="类别"
                      align="center"
                    >
                      <template scope="scope">
                        {{
                          scope.row.type === 1
                            ? "总局"
                            : scope.row.type === 2
                            ? "省局"
                            : "分局"
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="punNum"
                      label="罚单数（张）"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="numRatio"
                      label="占比"
                      align="center"
                    >
                      <template scope="scope">
                        {{ scope.row.numRatio }}%
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div
                style="
                  background: #f8f9fb;
                  padding: 8px 24px 24px 24px;
                  margin-left: 8px;
                "
              >
                <div class="table_title">
                  罚款金额
                  <div class="table_title_right">单位：万元</div>
                </div>
                <div
                  style="
                    width: 500px;
                    display: flex;
                    justify-content: center;
                    background: #f8f9fb;
                    margin-right: 8px;
                  "
                >
                  <el-table
                    :data="mainPunishTableData"
                    show-summary
                    border
                    style="width: 100%"
                    :header-cell-style="{ backgroundColor: '#EFF4F9' }"
                    :summary-method="getAmountSumaries"
                  >
                    <el-table-column
                      fixed
                      prop="type"
                      label="类别"
                      align="center"
                    >
                      <template scope="scope">
                        {{
                          scope.row.type === 1
                            ? "总局"
                            : scope.row.type === 2
                            ? "省局"
                            : "分局"
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="punMoney"
                      label="罚款金额（万元）"
                      align="center"
                    >
                      <template scope="scope">
                        {{ scope.row.punMoney.toFixed(2) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="moneyRatio"
                      label="占比"
                      align="center"
                    >
                      <template scope="scope">
                        {{ scope.row.moneyRatio }}%
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>

          <div v-show="showCharts.includes(4)">
            <div style="display: flex">
              <div class="chart_top">
                <div class="chart_title">
                  <img
                    src="@/assets/image/punish/book.png"
                    alt=""
                  />金监局处罚事由分析
                </div>
              </div>
              <div class="chart_action">
                <img src="@/icons/svg/change-chart.svg" alt="" />
                <span
                  style="margin-left: 5px"
                  @click="ChartOrTableChange('isShowAnalysChange')"
                  class="cur"
                  >列表视图</span
                ><img
                  src="@/icons/svg/download.svg"
                  alt=""
                  style="margin-right: 5px; margin-left: 20px"
                  v-show="!isShowAnalysChange"
                /><span
                  @click="exportImg('analysisChart', '金监局处罚事由分析')"
                  class="cur"
                  v-show="!isShowAnalysChange"
                  >下载</span
                >
              </div>
            </div>
            <div style="display: flex" v-if="!isShowAnalysChange">
              <div
                ref="analysisChart"
                style="
                  width: 1128px;
                  height: 950px;
                  display: flex;
                  justify-content: center;
                  background: #f8f9fb;
                  margin-right: 8px;
                  margin-left: 36px;
                "
              ></div>
            </div>
            <div
              style="
                margin-left: 20%;
                width: 60%;
                background: #f8f9fb;
                padding: 24px;
              "
              v-if="isShowAnalysChange"
            >
              <el-table
                :data="punishReasonTableData"
                border
                style=""
                :header-cell-style="{ backgroundColor: '#EFF4F9' }"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  width="60"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="punReason"
                  label="处罚事由"
                  align="center"
                >
                </el-table-column>

                <el-table-column
                  prop="punNum"
                  label="罚单数（张）"
                  width="154"
                  align="center"
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div v-show="isEmpty" class="empty">
          <img src="@/assets/image/punish/empty.png" alt="" />
          <div style="margin-left: 22px; margin-top: -50px; font-size: 12px">
            请输入查询条件，生成可视化图表
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getDutyListApi,
  getSeniorPunish,
  queryPunishTags,
  exportQueryExcel,
  exportExcel,
  querySenior,
} from "@/api/dutyListApi";
import { getChartsApi } from "@/api/punishApi";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      radioP: "",
      radioF: "",
      shiyouFlag: false,
      whichCity1: "",
      cityStatus1: {},
      cityCheckAll1: false,
      checkedCities1: [],
      cities1: [],
      firstCitys1: [],
      zhutiFlag: false,
      whichCity: "",
      cityStatus: {},
      cityCheckAll: false,
      checkedCities: [],
      cities: [],
      firstCitys: [],
      citieBanks: [],
      citieJinRs: [],
      punMoneyStart: "",
      punMoneyEnd: "",
      punSubZK: "展开处罚主体",
      punJyType: [],
      tagJyList: [],
      isMainChange: false,
      isSingleAreaChange: false,
      isDoublePunishChange: false,
      isShowPeopleChart: false,
      isAdminDataChange: false,
      isShowMainChange: false,
      isShowAnalysChange: false,
      isShowPunJyType: false,
      queryParam: {
        punTimeType: 3,
        punTimeBeg: "",
        punTimeEnd: "",
        govPubTimeType: 3,
        govPubTimeBeg: "",
        govPubTimeEnd: "",
        punJyType: "",
        punObjectList: [],
        subIds: [],
        dimensionList: [],
        punTimeRange: [],
        govPubTimeRange: [],
      },
      dimensionDictList: [
        { value: 1, name: "总体处罚情况" },
        { value: 2, name: "各地区处罚情况分布" },
        { value: 3, name: "双罚情况" },
        { value: 4, name: "处罚事由分析" },
        { value: 5, name: "处罚人员分布" },
        { value: 6, name: "行政处罚类别" },
        { value: 7, name: "处罚主体层级" },
      ],
      punObjectOptions: [],
      mainTableData: [{ name: "cc", value: 23 }],
      rules: {
        // punTimeType: [
        //   { required: true, message: "请选择处罚时间", trigger: "change" },
        // ],
        punTimeRange: [
          { required: true, message: "请选择处罚时间", trigger: "change" },
        ],
        govPubTimeType: [
          { required: true, message: "请选择监管发布时间", trigger: "change" },
        ],
        punObjectList: [
          {
            required: true,
            message: "请选择机构类型",
            trigger: "change",
            type: "array",
          },
        ],
        dimensionList: [
          { required: true, message: "请选择分析维度", trigger: "change" },
        ],
      },
      quantityData: [],
      showCharts: [],
      peopleData: [],
    };
  },
  computed: {
    isEmpty() {
      return !this.showCharts.length;
    },
  },
  watch: {
    "queryParam.punObjectList"(val) {
      if (val.includes("保险兼业代理")) {
        this.isShowPunJyType = true;
      } else {
        this.isShowPunJyType = false;
        this.queryParam.punJyType = "";
      }
    },
  },
  async created() {
    queryPunishTags().then((ret) => {
      console.log(ret);
      this.cities1 = ret.data.data.reasonList;
      this.firstCitys1 = ret.data.data.reasonList;
      this.reasonBankList = ret.data.data.reasonBankList;
      this.reasonList = ret.data.data.reasonList;
      this.citieBanks = ret.data.data.subBankList;
      this.punObjectOptions = ret.data.data.chartsObjectConfig
      this.citieJinRs = ret.data.data.subList;
      this.cities = ret.data.data.subList;
      this.firstCitys = ret.data.data.subList;
      this.expFlag = ret.data.data.expFlag;
      this.punTags = ret.data.data.tagList;
      this.configList = ret.data.data.configList;
      this.objectOptions = ret.data.data.configList;
      this.tagJyList = ret.data.data.tagJyList;
      ret.data.data.reasonList.map((item) => {
        for (let key in item) {
          this.cityStatus1[key] = false;
        }
      });
      ret.data.data.subList.map((item) => {
        for (let key in item) {
          this.cityStatus[key] = false;
        }
      });
      this.punTags.forEach((item) => {
        this.$set(item, "checkedLine", []);
      });
    });
  },
  methods: {
    initCharts() {
      this.$nextTick(() => {
        // this.initAmountChart();
        // this.initQuantityChart();
        // this.initSingleAreaChart();
        // this.initDoubleAreaChart();
        // this.initPeopleChart();
        // this.initAdminiChart();
        // this.initMainPunishQuantity();
        // this.initMainPunishAmount();
        // this.initAnalysisChart();
      });
    },
    search() {
      let pass;
      this.$refs.form.validate((valid) => {
        pass = !valid;
      });
      if (pass) {
        return;
      }

      if (this.queryParam.punTimeType === 4) {
        this.queryParam.punTimeBeg = this.queryParam.punTimeRange[0];
        this.queryParam.punTimeEnd = this.queryParam.punTimeRange[1];
        if(this.queryParam.punTimeEnd != undefined){
          this.queryParam.punTimeEnd = this.queryParam.punTimeEnd + " "+ "23:59:59";
        }
      } else {
        this.queryParam.punTimeBeg = "";
        this.queryParam.punTimeEnd = "";
      }
      if (this.queryParam.govPubTimeType === 4) {
        this.queryParam.govPubTimeBeg = this.queryParam.govPubTimeRange[0];
        this.queryParam.govPubTimeEnd = this.queryParam.govPubTimeRange[1];
        if(this.queryParam.govPubTimeEnd != undefined){
          this.queryParam.govPubTimeEnd = this.queryParam.govPubTimeEnd + " "+ "23:59:59";
        }
      } else {
        this.queryParam.govPubTimeBeg = "";
        this.queryParam.govPubTimeEnd = "";
      }

      this.queryParam.subIds = this.filterCityData(this.cities);
      if (this.punJyType.length > 0) {
        this.queryParam.punJyType = this.punJyType[0];
      } else {
        this.queryParam.punJyType = "";
      }
      getChartsApi(this.queryParam).then((res) => {
        if (res.data && res.data.code === 200) {
          if (this.queryParam.dimensionList.length > 0) {
            this.showCharts = this.queryParam.dimensionList;
          }
          const obj = res.data.data;
          if (obj.ztcfqkVO != null) {
            this.quantityData = [
              { name: "涉及机构罚单数", value: obj.ztcfqkVO.punJgNum },
              { name: "涉及个人罚单数", value: obj.ztcfqkVO.punGrNum },
              { name: "总罚单数", value: obj.ztcfqkVO.punSumNum },
            ];
            this.amountData = [
              {
                value: obj.ztcfqkVO.punJgMoney,
                name: "涉及机构罚款额",
                ratio:
                  (
                    (obj.ztcfqkVO.punJgMoney / obj.ztcfqkVO.punSumMoney) *
                    100
                  ).toFixed(2) + "%",
              },
              {
                value: obj.ztcfqkVO.punGrMoney,
                name: "涉及个人罚款额",
                ratio:
                  (
                    (obj.ztcfqkVO.punGrMoney / obj.ztcfqkVO.punSumMoney) *
                    100
                  ).toFixed(2) + "%",
              },
              {
                value: obj.ztcfqkVO.punSumMoney,
                name: "总罚款金额",
                ratio: "100%",
              },
            ];
            this.totalPunAmount = obj.ztcfqkVO.punSumMoney;
            this.$nextTick(() => {
              this.initAmountChart();
              this.initQuantityChart();
            });
          }

          if (obj.cfqkfbVOList !== null) {
            this.areaSingleData = obj.cfqkfbVOList.sort((a, b) => {
              return b.punMoney - a.punMoney;
            });

            this.titleList = this.areaSingleData.map((item) => {
              return { title: item.province, key: item.province };
            });
            this.titleList = [
              { title: "类别", key: "类别" },
              ...this.titleList,
            ];

            const sumNumList = this.areaSingleData.map((obj) => {
              return Object.keys(obj).reduce((acc, key) => {
                if (key == "punSumNum") {
                  const newkey = obj["province"];
                  acc[newkey] = obj[key];
                }
                return acc;
              }, {});
            });
            const punMoneyList = this.areaSingleData.map((obj) => {
              return Object.keys(obj).reduce((acc, key) => {
                if (key == "punMoney") {
                  const newkey = obj["province"];
                  acc[newkey] = obj[key];
                }
                return acc;
              }, {});
            });

            function mergeObjects(arr) {
              return arr.reduce((result, obj) => {
                for (let key in obj) {
                  result[key] = obj[key];
                }
                return result;
              }, {});
            }
            let sumList = mergeObjects(sumNumList);
            sumList["类别"] = "罚单数（张）";
            let moneyList = mergeObjects(punMoneyList);
            moneyList["类别"] = "罚款总额（万元）";
            this.dataList = [sumList, moneyList];
            this.areaPunishProvinceData = obj.cfqkfbVOList.map(
              (item) => item.province
            );
            this.areaPunishSumNumData = obj.cfqkfbVOList.map(
              (item) => item.punSumNum
            );
            this.areaPunishMoneyData = obj.cfqkfbVOList.map(
              (item) => item.punMoney
            );
            this.$nextTick(() => {
              this.initSingleAreaChart();
            });
          }

          if (obj.sfqkVOList !== null) {
            this.doublePunishData = obj.sfqkVOList?.sort((a, b) => {
              return b.proportion - a.proportion;
            });

            this.doubleTitleList = this.doublePunishData.map((item) => {
              return { title: item.province, key: item.province };
            });
            this.doubleTitleList = [
              { title: "类别", key: "类别" },
              ...this.doubleTitleList,
            ];

            const punJgNum = this.doublePunishData.map((obj) => {
              return Object.keys(obj).reduce((acc, key) => {
                if (key == "punJgNum") {
                  const newkey = obj["province"];
                  acc[newkey] = obj[key];
                }
                return acc;
              }, {});
            });
            const punSfNum = this.doublePunishData.map((obj) => {
              return Object.keys(obj).reduce((acc, key) => {
                if (key == "punSfNum") {
                  const newkey = obj["province"];
                  acc[newkey] = obj[key];
                }
                return acc;
              }, {});
            });
            let proportion = this.doublePunishData.map((obj) => {
              return Object.keys(obj).reduce((acc, key) => {
                if (key == "proportion") {
                  const newkey = obj["province"];
                  acc[newkey] = obj[key] * 100 + "%";
                }
                return acc;
              }, {});
            });

            function mergeObjects(arr) {
              return arr.reduce((result, obj) => {
                for (let key in obj) {
                  result[key] = obj[key];
                }
                return result;
              }, {});
            }
            let punJgNumObj = mergeObjects(punJgNum);
            punJgNumObj["类别"] = "双罚机构家次";
            let punSfNumObj = mergeObjects(punSfNum);
            punSfNumObj["类别"] = "双罚数";
            let proportionObj = mergeObjects(proportion);
            proportionObj["类别"] = "双罚占比";
            this.doubleDataList = [punSfNumObj, punJgNumObj, proportionObj];

            this.$nextTick(() => {
              this.initDoubleAreaChart();
            });
          }

          if (obj.cfryfbVOList !== null) {
            this.peopleData = obj.cfryfbVOList;
            this.sortData = JSON.parse(JSON.stringify(this.peopleData));
            this.maxMoney =
              this.sortData.sort((a, b) => {
                return b.punMoney - a.punMoney;
              })[0].punMoney + 20;
            this.maxSum =
              this.sortData.sort((a, b) => {
                return b.punPerNum - a.punPerNum;
              })[0].punPerNum + 20;
            // this.$nextTick(() => {
            // this.initPeopleChart();
            // });
          }
          if (obj.xzcflbVOList !== null) {
            this.adminData = obj.xzcflbVOList;
            this.$nextTick(() => {
              this.initAdminiChart();
            });
          }
          if (obj.cfztcjVOList !== null) {
            this.mainPunishData = obj.cfztcjVOList;
          }

          if (obj.cfztcjVOList != null) {
            this.mainPunishAmountTotal = this.mainPunishData.reduce(
              (acc, curr) => acc + curr.punMoney,
              0
            );
            this.mainPunishQuatityTotal = this.mainPunishData.reduce(
              (acc, curr) => acc + curr.punNum,
              0
            );
            this.mainPunishTableData = obj.cfztcjVOList?.map((item) => ({
              type: item.type,
              punNum: item.punNum,
              punMoney: item.punMoney,
              numRatio:((
                ((item.punNum / this.mainPunishQuatityTotal).toFixed(3)) *
                100
              )).toFixed(2),
              moneyRatio: (
                (item.punMoney / this.mainPunishAmountTotal) *
                100
              ).toFixed(2),
            }));

            this.$nextTick(() => {
              this.initMainPunishQuantity();
              this.initMainPunishAmount();
            });
          }

          if (obj.cfsyfxVOList !== null) {
            this.punishReasonTableData = JSON.parse(
              JSON.stringify(obj.cfsyfxVOList)
            );
            this.punishReasonData = obj.cfsyfxVOList?.sort((a, b) => {
              return a.punNum - b.punNum;
            });
            this.$nextTick(() => {
              this.initAnalysisChart();
            });
          }

          // this.initCharts();
        }
      });
    },
    reset() {
      this.queryParam = {
        punTimeType: 3,
        punTimeBeg: "",
        punTimeEnd: "",
        govPubTimeType: 3,
        govPubTimeBeg: "",
        govPubTimeEnd: "",
        punJyType: "",
        punObjectList: [],
        subIds: [],
        dimensionList: [],
      };
    },
    initQuantityChart() {
      var myChart = echarts.init(this.$refs.punishQuantity);
      var option;

      option = {
        label: {
          show: true,
          position: "top",
        },
        xAxis: {
          top: "20%",
          type: "category",
          data: ["总罚单数", "涉及个人罚单数", "涉及机构罚单数"],
        },
        yAxis: {
          type: "value",
          name: "单位/张",
          nameLocation: "end",
          nameTextStyle: {
            align: "left",
            padding: [0, 0, 10, 430],
          },
        },
        tooltip: {
          trigger: "item",
          formatter: function (param) {
            return param.name + "&nbsp;&nbsp;&nbsp;&nbsp;" + param.value;
          },
        },
        series: [
          {
            data: this.quantityData
              .filter((item) => {
                return item.value;
              })
              .sort((a, b) => {
                return b.value - a.value;
              }),
            type: "bar",
            itemStyle: {
              color: "#5B8FF9",
            },
            barWidth: "30%",
          },
        ],
      };
      option && myChart.setOption(option);
    },

    initAmountChart() {
      var myChart = echarts.init(this.$refs.punishAmount);
      var option;

      option = {
        title: {
          text: "总罚款金额",
          subtext: this.totalPunAmount,
          left: "39%",
          top: "42%",
          textAlign: "center",
          textStyle: {
            fontSize: 12,
            color: "#606266",
            align: "center",
          },
          subtextStyle: {
            fontFamily: "微软雅黑",
            fontSize: 22,
            color: "#000000",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: function (param) {
            return (
              param.data.name +
              "<br>" +
              param.marker +
              param.value +
              "&nbsp;&nbsp;&nbsp;&nbsp;" +
              param.percent +
              "%"
            );
          },
        },
        graphic: {
          type: "text",
          right: 20, // 距离右边的距离
          top: 20, // 距离顶部的距离
          style: {
            text: "单位：万元", // 显示的文字
            textAlign: "left",
            fill: "#606266", // 文字颜色
            fontSize: 12,
          },
        },
        legend: {
          bottom: "25%",
          left: "70%",
          orient: "vertical",
          icon: "circle",
        },
        color: ["#5B8FF9", "#60D2A5"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["40%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "inner",
              formatter: function (param) {
                return param.value;
              },
              textStyle: {
                color: "white",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.amountData?.filter((item) => item.name != "总罚款金额"),
          },
        ],
      };

      option && myChart.setOption(option);
    },

    initSingleAreaChart() {
      var myChart = echarts.init(this.$refs.singleAreaChart);
      var option;
      option = {
        title: {
          left: "left",
          text: "单位：张",
          show: false,
        },
        legend: { top: "bottom" },
        tooltip: {
          trigger: "axis",
          formatter: function (param) {
            return (
              "罚单数" +
              "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
              param[1].value +
              "<br>" +
              "罚款金额" +
              "&nbsp;&nbsp;&nbsp;&nbsp;" +
              param[0].value
            );
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          show: false,
          top: "50",
          bottom: "20%",
          right: "60",
          left: "60",
        },
        xAxis: [
          {
            type: "category",
            data: this.areaPunishProvinceData,
            axisPointer: {
              type: "shadow",
            },

            axisTick: {
              show: true,
              interval: 0,
            },
            axisLabel: {
              formatter: function (value) {
                return value.split("").join("\n");
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：万元",
            show: true,
            // interval: 50,
          },
          {
            type: "value",
            name: "单位：张",
            // min: 0,
            // max: 100,
            // interval: 10,
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],

        series: [
          {
            name: "罚款金额",
            type: "bar",
            data: this.areaPunishMoneyData,
            // barWidth: "50%",
            itemStyle: {
              color: "#5B8FF9",
              barBorderRadius: [20, 20, 20, 20],
            },
          },
          {
            name: "罚单数",
            type: "line",
            yAxisIndex: 1, //这里要设置哪个y轴，默认是最左边的是0，然后1，2顺序来。
            data: this.areaPunishSumNumData,
            symbolSize: 0,
            itemStyle: {
              normal: {
                color: "#E5940F",
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },

    initDoubleAreaChart() {
      var myChart = echarts.init(this.$refs.doubleAreaChart);
      var option;
      option = {
        title: {
          left: "left",
          text: "单位：张",
          show: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          formatter: function (param) {
            return (
              "双罚占比" + "&nbsp;&nbsp;&nbsp;&nbsp" + param[0].value + "%"
            );
          },
        },
        grid: {
          show: false,
          top: "30",
          bottom: "60",
          right: "60",
          left: "60",
        },
        xAxis: [
          {
            type: "category",
            data: this.doublePunishData?.map((item) => item.province),
            axisPointer: {
              type: "shadow",
            },

            axisTick: {
              show: true,
              interval: 0,
            },
            axisLabel: {
              formatter: function (value) {
                return value.split("").join("\n");
              },
            },
          },
        ],

        yAxis: [
          {
            type: "value",
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],

        series: [
          {
            type: "bar",
            data: this.doublePunishData?.map((item) => item.proportion * 100),
            barWidth: "50%",
            itemStyle: {
              color: "#67D4A9",
              barBorderRadius: [20, 20, 20, 20],
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },

    initPeopleChart() {
      if (this.peopleData === null) {
        return;
      }
      var myChart = echarts.init(this.$refs.peopleChart);
      var option;
      const addr_arr = this.peopleData?.map((item) => item.identityCategory);

      const data1 = this.peopleData?.map((item) => item.punMoney); // 监测设备

      const data22 = this.peopleData?.map((item) => item.punPerNum); // 监测点
      const data2 = [];

      const maxPerCent = Math.max.apply(null, data22); // 完成率中的最大值
      const maxnumber = Math.max.apply(null, data1); //监测设备的最大值
      //为了实现对称，向每个数组推最大的数，并不显示在页面
      const maxallnumber = Math.max(maxPerCent, maxnumber);
      data1.push(maxallnumber);
      data22.push(maxallnumber);
      data22.forEach(function (ele) {
        data2.push(-Math.abs(ele));
      }); // 监测点的负数转换，形成新数组
      option = {
        title: {},

        tooltip: {
          trigger: "axis",

          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效

            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },

          formatter: function (params) {
            return (
              params[0].name +
              "<br>金额：" +
              params[1].value +
              "万元" +
              "<br>人数：" +
              Math.abs(data22[params[0].dataIndex]) +
              "人"
            ); // 换算
          },
        },

        grid: {
          top: "2.5%",
          // left: "10%",
          left: "25%",
          // right: "0%",
          right: "-10%",
          bottom: "0%",
          containLabel: true,
        },

        xAxis: [
          {
            show: false,
            type: "value",

            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "bottom",

            axisLabel: {
              fontSize: 10,
              fontFamily: "Microsoft YaHei",
              fontWeight: "bold",
              color: "#FFFFFF",
              lineHeight: 18,
              formatter: function (value, index) {
                //将X轴的数字转换为正数
                if (value < 0) {
                  return -value;
                } else {
                  return value;
                }
              },
            },
          },
        ],

        yAxis: [
          {
            type: "category",
            position: "right",
            //去掉y轴的线
            axisLine: {
              show: false,
              lineStyle: {},
            },
            axisTick: {
              show: false,
            },
            nameLocation: "center",
            nameTextStyle: {
              verticalAlign: "middle",
            },
            //y轴的偏移，后期可以根据props传值进行计算
            // offset: -420,
            offset: -900,
            //层级
            zlevel: 0,

            data: addr_arr, // 更改数组

            axisLabel: {
              fontSize: 16,
              fontFamily: "Microsoft YaHei",
              color: "white",
              lineHeight: 18,
              width: 180,
              height: 30,
              borderRadius: [5, 5, 5, 5],
              backgroundColor: "#1650C7",
              verticalAlign: "middle",
              align: "center",
            },
          },
        ],

        series: [
          {
            name: "监测点",
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              position: "left",
              formatter: function (params) {
                return Math.abs(data22[params.dataIndex]) + "人"; // 换算
              },
            },

            itemStyle: {
              color: "#5B8FF9",
            },
            //中间图形的高度
            barWidth: 22,
            showBackground: true,

            data: data2,
          },

          {
            name: "监测设备",

            type: "bar",

            stack: "总量",

            label: {
              show: true,
              position: "right",
              formatter: function (params) {
                return params.value + "万元";
              },
            },

            itemStyle: {
              color: "#67D4A9",
            },
            barWidth: 22,

            data: data1,
          },
        ],
      };
      option && myChart.setOption(option);
    },

    initAdminiChart() {
      var myChart = echarts.init(this.$refs.adminiChart);
      var option;
      option = {
        title: {
          left: "left",
          text: "单位：张",
          show: false,
        },
        label: {
          show: true,
          position: "top",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (param) {
            return (
              param[0].axisValue +
              "&nbsp;&nbsp;&nbsp;&nbsp" +
              param[0].value +
              "次"
            );
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          show: false,
          top: "30",
          bottom: "60",
          right: "60",
          left: "60",
        },
        xAxis: [
          {
            type: "category",
            data: this.adminData?.map((item) => item.othorName),
            axisPointer: {
              type: "shadow",
            },

            axisTick: {
              show: true,
              interval: 0,
            },
          },
        ],

        yAxis: [
          {},
          {
            type: "value",
            name: "单位：次",
          },
        ],

        series: [
          {
            type: "bar",
            data: this.adminData?.map((item) => item.punNum),
            barWidth: "50%",
            itemStyle: {
              color: "#5B8FF9",
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },

    initMainPunishQuantity() {
      var myChart = echarts.init(this.$refs.mainPunishQuantity);
      var option;

      option = {
        tooltip: {
          trigger: "item",
          formatter: function (param) {
            return (
              param.data.name +
              "<br>" +
              param.marker +
              param.value +
              "张" +
              "&nbsp;&nbsp;&nbsp;&nbsp;" +
              param.percent +
              "%"
            );
          },
        },
        graphic: {
          type: "text",
          right: 20, // 距离右边的距离
          top: 20, // 距离顶部的距离
          style: {
            text: "单位：张", // 显示的文字
            textAlign: "left",
            fill: "#606266", // 文字颜色
            fontSize: 12,
          },
        },
        legend: {
          bottom: "25%",
          left: "85%",
          orient: "vertical",
          icon: "circle",
        },
        color: ["#60D2A5", "#5B8FF9", "#F29C11"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["40%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            label: {
              show: true,
              position: "inner",
              formatter: function (param) {
                return param.value;
              },
              textStyle: {
                color: "white",
              },
            },
            data: this.mainPunishData?.map((item) => ({
              name:
                item.type === 1 ? "总局" : item.type === 2 ? "省局" : "分局",
              value: item.punNum,
            })),
          },
        ],
      };

      option && myChart.setOption(option);
    },
    initMainPunishAmount() {
      var myChart = echarts.init(this.$refs.mainPunishAmount);
      var option;

      option = {
        tooltip: {
          trigger: "item",
          formatter: function (param) {
            return (
              param.data.name +
              "<br>" +
              param.marker +
              param.value +
              "万元" +
              "&nbsp;&nbsp;&nbsp;&nbsp;" +
              param.percent +
              "%"
            );
          },
        },
        graphic: {
          type: "text",
          right: 20, // 距离右边的距离
          top: 20, // 距离顶部的距离
          style: {
            text: "单位：万元", // 显示的文字
            textAlign: "left",
            fill: "#606266", // 文字颜色
            fontSize: 12,
          },
        },
        legend: {
          bottom: "25%",
          left: "85%",
          orient: "vertical",
          icon: "circle",
        },
        color: ["#60D2A5", "#5B8FF9", "#F29C11"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["40%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            label: {
              show: true,
              position: "inner",
              formatter: function (param) {
                return param.value;
              },
              textStyle: {
                color: "white",
              },
            },
            data: this.mainPunishData?.map((item) => ({
              name:
                item.type === 1 ? "总局" : item.type === 2 ? "省局" : "分局",
              value: item.punMoney.toFixed(2),
            })),
          },
        ],
      };

      option && myChart.setOption(option);
    },

    initAnalysisChart() {
      var myChart = echarts.init(this.$refs.analysisChart);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
          formatter: function (param) {
            return (
              param[0].axisValue +
              "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
              param[0].value +
              "张"
            );
          },
        },
        graphic: {
          type: "text",
          right: 20, // 距离右边的距离
          top: 20, // 距离顶部的距离
          style: {
            text: "单位：张", // 显示的文字
            textAlign: "left",
            fill: "#606266", // 文字颜色
            fontSize: 12,
          },
        },
        grid: {
          top: "5%",
          left: "30%",
          right: "14%",
          bottom: "10%",
          containLabel: false,
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          type: "category",
          data: this.punishReasonData?.map((item) => item.punReason),
        },
        series: [
          {
            name: "Direct",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              position: "right",
            },
            emphasis: {
              focus: "series",
            },
            // barWidth: "16",
            barCategoryGap: "40%",
            showBackground: true,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#83bff6" },
                { offset: 0.5, color: "#188df0" },
                { offset: 1, color: "#188df0" },
              ]),
            },
            itemStyle: {
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: "#366EDE",
                  },
                  {
                    offset: 1,
                    color: "#5B8FF9",
                  },
                ],
              },
              barBorderRadius: [0, 20, 20, 0],
            },
            data: this.punishReasonData?.map((item) => item.punNum),
          },
        ],
      };

      option && myChart.setOption(option);
    },
    // 城市选择事件
    cityCheckAllChange1(val) {
      if (val) {
        this.cities1.map((item) => {
          for (let key in item) {
            item[key].map((item2) => {
              item2.checked = true;
            });
          }
        });
        for (let key in this.cityStatus1) {
          this.cityStatus1[key] = true;
        }
      } else {
        this.cities1.map((item) => {
          for (let key in item) {
            item[key].map((item2) => {
              item2.checked = false;
            });
          }
        });
        for (let key in this.cityStatus1) {
          this.cityStatus1[key] = false;
        }
      }
      this.cities1 = [...this.cities1];
    },
    firstCitiesChange1(status, value) {
      let tempAllStatus = true;
      if (status) {
        this.cities1.map((item) => {
          for (let key in item) {
            if (key == value) {
              item[key].map((item2) => {
                item2.checked = true;
              });
            }
          }
        });
      } else {
        this.cities1.map((item) => {
          for (let key in item) {
            if (key == value) {
              item[key].map((item2) => {
                item2.checked = false;
              });
            }
          }
        });
      }
      this.cities1 = [...this.cities1];
      for (let key in this.cityStatus1) {
        if (!this.cityStatus1[key]) {
          tempAllStatus = false;
        }
      }
      this.cityCheckAll1 = tempAllStatus;
    },
    secondCitiesChange1(status, value, parent) {
      let tempAllStatus = true;
      if (status) {
        let tempStatus = true;
        this.cities1.map((item) => {
          for (let key in item) {
            if (key == parent) {
              item[key].map((item2) => {
                if (!item2.checked || item2.checked == false) {
                  tempStatus = false;
                }
              });
            }
          }
        });
        this.cityStatus1[parent] = tempStatus;
      } else {
        this.cityStatus1[parent] = false;
      }
      for (let key in this.cityStatus1) {
        if (!this.cityStatus1[key]) {
          tempAllStatus = false;
        }
      }
      this.cityCheckAll1 = tempAllStatus;
      this.cities1 = [...this.cities1];
      this.cityStatus1 = { ...this.cityStatus1 };
    },

    // 城市数据初始化设置
    filterCityData1(city) {
      let result = [];
      city.map((item) => {
        for (let key in item) {
          item[key].map((item2) => {
            if (item2.checked) {
              result.push(item2.id);
            }
          });
        }
      });
      return result;
    },
    // 显示二级城市选择
    showMoreReason(current) {
      if (this.whichCity1 && this.whichCity1 == current) {
        this.whichCity1 = "";
      } else {
        this.whichCity1 = current;
      }
    },

    // 城市选择事件
    cityCheckAllChange(val) {
      if (val) {
        this.cities.map((item) => {
          for (let key in item) {
            item[key].map((item2) => {
              item2.checked = true;
            });
          }
        });
        for (let key in this.cityStatus) {
          this.cityStatus[key] = true;
        }
      } else {
        this.cities.map((item) => {
          for (let key in item) {
            item[key].map((item2) => {
              item2.checked = false;
            });
          }
        });
        for (let key in this.cityStatus) {
          this.cityStatus[key] = false;
        }
      }
      this.cities = [...this.cities];
    },
    firstCitiesChange(status, value) {
      let tempAllStatus = true;
      if (status) {
        this.cities.map((item) => {
          for (let key in item) {
            if (key == value) {
              item[key].map((item2) => {
                item2.checked = true;
              });
            }
          }
        });
      } else {
        this.cities.map((item) => {
          for (let key in item) {
            if (key == value) {
              item[key].map((item2) => {
                item2.checked = false;
              });
            }
          }
        });
      }
      this.cities = [...this.cities];
      for (let key in this.cityStatus) {
        if (!this.cityStatus[key]) {
          tempAllStatus = false;
        }
      }
      this.cityCheckAll = tempAllStatus;
    },
    secondCitiesChange(status, value, parent) {
      let tempAllStatus = true;
      if (status) {
        let tempStatus = true;
        this.cities.map((item) => {
          for (let key in item) {
            if (key == parent) {
              item[key].map((item2) => {
                if (!item2.checked || item2.checked == false) {
                  tempStatus = false;
                }
              });
            }
          }
        });
        this.cityStatus[parent] = tempStatus;
      } else {
        this.cityStatus[parent] = false;
      }
      for (let key in this.cityStatus) {
        if (!this.cityStatus[key]) {
          tempAllStatus = false;
        }
      }
      this.cityCheckAll = tempAllStatus;
      this.cities = [...this.cities];
      this.cityStatus = { ...this.cityStatus };
    },
    // 显示二级城市选择
    showMoreCitys(current) {
      if (this.whichCity && this.whichCity == current) {
        this.whichCity = "";
      } else {
        this.whichCity = current;
      }
    },
    // 城市数据初始化设置
    filterCityData(city) {
      let result = [];
      city.map((item) => {
        for (let key in item) {
          item[key].map((item2) => {
            if (item2.checked) {
              result.push(item2.id);
            }
          });
        }
      });
      return result;
    },
    morePunSub() {
      if (this.zhutiFlag) {
        this.zhutiFlag = false;
        this.punSubZK = "展开处罚主体";
      } else {
        this.zhutiFlag = true;
        this.shiyouFlag = false;
        this.punSubZK = "收起处罚主体";
      }
    },
    ChartOrTableChange(name) {
      this[name] = !this[name];
      if (name === "isMainChange") {
        if (!this[name]) {
          this.$nextTick(() => {
            this.initQuantityChart();
            this.initAmountChart();
          });
        }
      } else if (name === "isSingleAreaChange") {
        if (!this[name]) {
          this.$nextTick(() => {
            this.initSingleAreaChart();
          });
        }
      } else if (name === "isDoublePunishChange") {
        if (!this[name]) {
          this.$nextTick(() => {
            this.initDoubleAreaChart();
          });
        }
      } else if (name === "isShowPeopleChart") {
        if (!this[name]) {
          // this.$nextTick(() => {
          //   this.initPeopleChart();
          // });
        }
      } else if (name === "isAdminDataChange") {
        if (!this[name]) {
          this.$nextTick(() => {
            this.initAdminiChart();
          });
        }
      } else if (name === "isShowMainChange") {
        if (!this[name]) {
          this.$nextTick(() => {
            this.initMainPunishQuantity();
            this.initMainPunishAmount();
          });
        }
      } else if (name === "isShowAnalysChange") {
        if (!this[name]) {
          this.$nextTick(() => {
            this.initAnalysisChart();
          });
        }
      }
    },
    exportImg(dom, name) {
      const src = echarts
        .init(this.$refs[dom])
        .getDataURL({ pixelRatio: 2, backgroundColor: "#fff" });
      const a = document.createElement("a");
      a.href = src;
      a.download = name; // 设置下载的文件名
      a.click(); // 模拟点击下载
    },
    exportDoubleImg(charts) {
      charts.forEach((item) => {
        this.exportImg(item.dom, item.name);
      });
    },
    keepTwoDecimalFull(num) {
      var result = parseFloat(num);
      if (isNaN(result)) {
        alert("传递参数错误，请检查！");
        return false;
      }
      result = Math.round(num * 100) / 100;
      var s_x = result.toString(); //将数字转换为字符串

      var pos_decimal = s_x.indexOf("."); //小数点的索引值

      // 当整数时，pos_decimal=-1 自动补0
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }

      // 当数字的长度< 小数点索引+2时，补0
      while (s_x.length <= pos_decimal + 2) {
        s_x += "0";
      }
      return s_x;
    },
    back() {
      this.$router.go(-1);
    },
    moneyFormat(index) {
      if (index !== undefined) {
        return () => {
          return this.peopleData[index].punMoney + "万元";
        };
      }
    },
    numFormat(index) {
      if (index !== undefined) {
        return () => {
          return this.peopleData[index].punPerNum + "人";
        };
      }
    },
    moneyPercentFun(item, index) {
      return (item.punMoney / this.maxMoney) * 100;
    },
    sumPercentFun(item, index) {
      return (item.punPerNum / this.maxSum) * 100;
    },
    generateImage() {
      const element = document.getElementById("peopleChart"); // 替换为目标元素的id或class
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = "处罚人员分布.png";
        link.click();
      });
    },
    getNumSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index === 1) {
            sums[index];
          } else if (index === 2) {
            if (column.property == 'numRatio') {
              sums[index] = 100 + "%";
              return
            }
            sums[index] += "%";
          }
        }
      });

      return sums;
    },
    getAmountSumaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index === 1) {
            sums[index];
          } else if (index === 2) {
            sums[index] += "%";
          }
        }
      });

      return sums;
    },
  },
};
</script>
  
<style scoped >
::v-deep .el-input-coler.el-input .el-input__inner {
  color: #2696ff;
}
.title {
  height: 40px;
  background: #2468f2;
  display: flex;
  color: white;
}
.title_left {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-left: 20%;
}
.title_right {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-left: 54%;
  cursor: pointer;
}
.title_icon {
  width: 28px;
  margin-right: 5px;
}
.title_icon_right {
  width: 12px;
  margin-right: 5px;
}
.chart_top {
  margin-left: 14px;
}
.chart_title > img {
  width: 44px;
  padding-right: 5px;
}
.chart_title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #0e0e0e;
  line-height: 45px;
  text-align: left;
  font-style: normal;
  padding: 20px;
  display: flex;
}
.chart_action {
  color: #2696ff;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 3.3%;
}
.senior_page {
  .el-radio {
    color: #606266;
    font-weight: 500;
    cursor: pointer;
    margin-right: 0.05rem;
  }
  .el-range-editor.el-input__inner {
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.001625rem 0.052083rem;
  }
}
.main_background_p {
  background: url("../../../assets/image/punish/punish.png") no-repeat;
  background-size: 100%;
  width: 100%;
  height: 1.125rem;
  overflow: hidden;
  text-align: left;
}

.main_background_p > p,
.main_background > div {
  width: 79%;
  margin: 0 auto;
}
.main_background_p > #punish_p {
  margin-top: 48px;
  color: rgba(8, 40, 105, 1);
  font-size: 28px;
  font-weight: 900;
}
.main_background_p > #punish_p + p {
  color: rgba(8, 40, 105, 1);
  font-size: 16px;
  margin: 14px auto;
}

.main_background {
  width: 99.65%;
  background-color: #e2f0ff;
  min-height: 280px;
  margin-left: 3px;
  height: auto;
  overflow: hidden;
  text-align: left;
}

.el-form-item {
  margin-bottom: 0px;
}
.main_background > p,
.main_background > div {
  width: 100%;
  margin: 0 auto;
}

.main_background > #punish_p + p {
  color: rgba(8, 40, 105, 1);
  font-size: 16px;
  margin: 14px auto;
}
.chart {
  margin: auto;
  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 580px;
}
.half_chart {
  width: 560px;
  height: 376px;
  display: flex;
  justify-content: center;
  background: #f8f9fb;
}
.empty {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 125px;
  width: 1200px;
  padding-bottom: 30px;
}
.empty > img {
  width: 282px;
}
.main_punish_quantity {
  margin-bottom: -38px;
  margin-left: 61px;
  z-index: 9999999;
  position: sticky;
  font-size: 16px;
  color: #303133;
  font-weight: 600;
}
.main_punish_amount {
  margin-bottom: -38px;
  margin-left: 24px;
  z-index: 9999999;
  position: sticky;
  font-size: 16px;
  color: #303133;
  font-weight: 600;
}
.cur {
  cursor: pointer;
}
::v-deep .el-form-item__error {
  margin-left: 0;
}
.table_background {
  background: #eff4f9;
}
/*::v-deep .el-table thead {*/
/*color: black;*/
/*}*/
::v-deep .el-progress-bar__outer {
  border-radius: 0px !important;
  overflow: unset;
}

::v-deep .el-progress-bar__inner {
  border-radius: 0px !important;
}

::v-deep .left_people .el-progress-bar__innerText {
  transform: rotate(180deg) !important;
}
::v-deep .el-progress-bar__innerText {
  position: absolute;
  margin-top: 5px;
  margin-left: 10px;
  transform: rotate(0deg) !important;
}
.table_title {
  padding-bottom: 17px;
  padding-top: 3px;
  font-weight: 600;
  font-size: 16px;
}
.table_title_right {
  float: right;
  padding-right: 10px;
  font-weight: normal;
}
.single_table_background {
  padding: 24px;
  background: #f8f9fb;
}
</style>